import React from 'react';
import { Button, Flex } from 'rebass/styled-components';
import { Select } from '@rebass/forms/styled-components';
import styled from 'styled-components';
import { Text } from '@tamo-marketplace/genie';

import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import Icon, { IconType } from 'components-shared/icon/Icon';
import { BASE_TABLE_PAGINATION_ROWS_PER_PAGE } from 'constants/textResources';
import { StyleColor } from 'models/utils';

const PaginationSelect = styled(Select)(
  ({ theme: { colors, radii, space } }) => ({
    borderRadius: radii.md,
    borderColor: colors.neutral120,
    padding: space[8],
    minWidth: '60px',
    '&:disabled': {
      backgroundColor: colors.neutral80,
    },
    '&:invalid,& option[value=""]': {
      color: colors.neutral80,
    },
  })
);

const StyledPaginationButton = styled(Button)(
  ({ theme: { colors, radii, space } }) => ({
    color: colors.neutral120,
    backgroundColor: 'transparent',
    padding: space[8],
    border: '1px solid',
    borderColor: colors.neutral120,
    borderRadius: radii.lg,
    transition: '300ms background-color',
    '&:hover:not(:disabled)': {
      cursor: 'pointer',
      backgroundColor: colors.neutral40,
    },
    '&:disabled': {
      cursor: 'default',
      color: colors.neutral60,
      borderColor: colors.neutral60,
    },
    '&:not(:last-child)': {
      marginRight: space[16],
    },
  })
);

const CustomText = styled(Text)`
  margin-right: 8px;
`;

export type PaginationButtonProps = {
  onClick: () => void;
  icon: IconType;
  loading?: boolean;
  disabled: boolean;
  flip?: boolean;
};

const PaginationButton = ({
  onClick,
  loading,
  disabled,
  icon,
  flip,
  ...props
}: PaginationButtonProps) => {
  const {
    theme: { colors },
  } = useTheme();

  const enabledColor = colors.neutral120 as StyleColor;
  const disabledColor = colors.neutral60 as StyleColor;

  return (
    <StyledPaginationButton
      onClick={!loading ? onClick : undefined}
      disabled={disabled}
      {...props}
    >
      <Icon
        name={icon}
        size={16}
        rotateDeg={flip ? 180 : 0}
        color={disabled ? disabledColor : enabledColor}
      />
    </StyledPaginationButton>
  );
};

export type PaginationProps = {
  pageIndex: number;
  pageCount: number;
  pageSize: number;
  totalRows: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  rowPerPage: number[];
  handlePageSizeChange: (pageNumber: number) => void;
  goToPage: (pageNumber: number) => void;
};

const BaseTablePagination = ({
  pageIndex,
  pageCount,
  pageSize,
  totalRows,
  canNextPage,
  canPreviousPage,
  rowPerPage,
  handlePageSizeChange,
  goToPage,
}: PaginationProps) => {
  const {
    theme: { space },
  } = useTheme();
  const startRow = pageIndex * pageSize;
  const endRow = startRow + pageSize;

  const previousPage = () => goToPage(pageIndex - 1);
  const nextPage = () => goToPage(pageIndex + 1);

  return (
    <Flex
      className="pagination"
      justifyContent="center"
      alignItems="center"
      mt={space[16]}
      data-testid="table-pagination-wrapper"
    >
      <Flex justifyContent="space-between" width="100%" maxWidth="600px">
        <Flex alignItems="center">
          <CustomText>{BASE_TABLE_PAGINATION_ROWS_PER_PAGE}</CustomText>
          <PaginationSelect
            defaultValue={pageSize}
            onChange={(e: { target: { value: string } }) =>
              handlePageSizeChange(Number(e.target.value))
            }
            data-testid="table-pagination-rows-select"
          >
            {rowPerPage.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </PaginationSelect>
        </Flex>
        <Flex alignItems="center">
          <span>
            <span data-testid="table-pagination-start-row">{startRow + 1}</span>
            {' - '}
            <span data-testid="table-pagination-end-row">
              {totalRows < endRow ? totalRows : endRow}
            </span>
            {' of '}
            <span data-testid="table-pagination-total-rows">{totalRows}</span>
          </span>
        </Flex>
        <Flex
          sx={{ alignItems: 'center', '&:not(:last-child)': { mr: space[16] } }}
        >
          <PaginationButton
            onClick={() => goToPage(0)}
            disabled={!canPreviousPage}
            icon="arrow_end"
            flip
            data-testid="table-pagination-start-button"
          />
          <PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
            icon="arrow_thin"
            flip
            data-testid="table-pagination-previous-button"
          />
          <PaginationButton
            onClick={nextPage}
            disabled={!canNextPage}
            icon="arrow_thin"
            data-testid="table-pagination-next-button"
          />
          <PaginationButton
            onClick={() => goToPage(pageCount - 1)}
            disabled={!canNextPage}
            icon="arrow_end"
            data-testid="table-pagination-end-button"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BaseTablePagination;
