import React from 'react';
import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from 'react-table';
import { Flex } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import { Text } from '@tamo-marketplace/genie';
import { useTheme } from '@tamannabit/design-system.foundations.context.main-theme';
import { BaseTableProps } from './BaseTable';
import Icon from 'components-shared/icon/Icon';
import { ObjectOfAny, StyleColor } from 'models/utils';
import { getKebabCase, isEmpty } from 'utils/primitives';

const Table = styled.table(({ theme: { fontSizes } }) => ({
  width: '100%',
  fontSize: fontSizes[14],
}));

const TH = styled.th(
  ({ theme: { colors } }) => css`
    background-color: ${colors.summer100};
    height: 48px;
  `
);

const TD = styled.td(
  ({ theme: { colors, space } }) => css`
    margin-top: ${space[4]} !important;
    border-bottom: 0px !important;
    background-color: ${colors.neutral00};
  `
);

const CenterFlexCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataRow = ({ noDataText }: { noDataText?: string }) => {
  const {
    theme: { fontSizes },
  } = useTheme();

  return (
    <tr>
      <CenterFlexCell height="200px">
        <Text fontSize={fontSizes[18]} data-testid="table-no-data-text">
          {noDataText || 'No data to display'}
        </Text>
      </CenterFlexCell>
    </tr>
  );
};

const SortingArrows = ({
  isSorted,
  isSortedDesc,
}: {
  isSorted: boolean;
  isSortedDesc: boolean;
}) => {
  const {
    theme: { colors, space },
  } = useTheme();

  const activeColor = colors.neutral120 as StyleColor;
  const defaultColor = colors.neutral00 as StyleColor;

  return (
    <Flex alignItems="center" data-testid="table-sort-icons" ml={space[8]}>
      <Icon
        name="arrow_narrow_down"
        color={isSorted && isSortedDesc ? activeColor : defaultColor}
        rotateDeg={180}
        size={14}
      />
      <Icon
        name="arrow_narrow_down"
        color={isSorted && !isSortedDesc ? activeColor : defaultColor}
        size={14}
        style={{ ml: space[2] }}
      />
    </Flex>
  );
};

export type TableContentProps<T extends ObjectOfAny> = {
  /* react-table specific props */
  headerGroups: HeaderGroup<T>[];
  page: Row<T>[];
  rows: Row<T>[];
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<T> | undefined
  ) => TableBodyProps;
  getTableProps: (propGetter?: TablePropGetter<T> | undefined) => TableProps;
  prepareRow: (row: Row<T>) => void;
  loading?: boolean;
  tableAriaLabel?: string;
} & Pick<
  BaseTableProps<T>,
  'sortable' | 'noDataText' | 'paginated' | 'data' | 'onRowClick'
>;

const BaseTableContent = <T extends ObjectOfAny>({
  headerGroups,
  sortable: isSortable,
  data,
  page: propPage,
  rows: propRows,
  paginated: isPaginated,
  getTableBodyProps,
  getTableProps,
  noDataText,
  prepareRow,
  onRowClick,
  tableAriaLabel,
}: TableContentProps<T>) => {
  const hasData = !isEmpty(data);
  const rows = isPaginated ? propPage : propRows;

  return (
    <Table {...getTableProps()} aria-label={tableAriaLabel}>
      <thead>
        {headerGroups.map((headerGroup, groupIndex: number) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={getKebabCase('table-head-row', groupIndex)}
          >
            {headerGroup.headers.map((column, columnIndex: number) => (
              <TH
                {...column.getHeaderProps(
                  isSortable && hasData
                    ? column.getSortByToggleProps()
                    : undefined
                )}
                key={getKebabCase('table-header', columnIndex)}
                data-testid={getKebabCase('table-column-header', column.Header)}
              >
                {column.render('Header')}
                {!isSortable && hasData && (
                  <SortingArrows
                    isSorted={column.isSorted}
                    isSortedDesc={!!column.isSortedDesc}
                  />
                )}
              </TH>
            ))}
          </tr>
        ))}
      </thead>
      <AnimatePresence>
        {data != null && (
          <motion.tbody
            transition={{ duration: 0.3 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            {...getTableBodyProps()}
          >
            {data?.length === 0 ? (
              <NoDataRow noDataText={noDataText} />
            ) : (
              rows?.map((row, rowIndex: number) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps?.()}
                    {...(onRowClick
                      ? {
                          onClick: () => onRowClick?.(row.original),
                          className: 'clickable',
                        }
                      : {})}
                    key={getKebabCase('table-body-row', rowIndex)}
                    data-testid={getKebabCase('table-body-row', rowIndex)}
                  >
                    {row.cells.map((cell, cellIndex) => (
                      <TD
                        className={'item' + cellIndex}
                        {...cell.getCellProps()}
                        key={getKebabCase('table-cell', cellIndex)}
                        data-testid={getKebabCase(
                          'table-body-cell',
                          cell.column?.Header
                        )}
                      >
                        {cell.render('Cell')}
                      </TD>
                    ))}
                  </tr>
                );
              })
            )}
          </motion.tbody>
        )}
      </AnimatePresence>
    </Table>
  );
};

export default BaseTableContent;
