import React, { useEffect, useState } from 'react';
import {
  Column,
  SortingRule,
  TableOptions,
  TableState,
  useFlexLayout,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { Flex, SxStyleProp, Text } from 'rebass/styled-components';
import styled, { css } from 'styled-components';
import PaginationControls from './BaseTablePagination';
import TableContent from './BaseTableContent';
import { fadeInOpacity } from 'styles';

import Spinner from 'components-shared/Spinner';
import { isEmpty } from 'utils/primitives';
import { Modify, Nullable, ObjectOfAny } from 'models/utils';

const ROWS_PER_PAGE = [10, 30, 50, 100];

const LoadingFlex = styled(Flex)<{ hasData?: boolean }>(
  ({ hasData, theme: { colors } }) => css`
    position: absolute;
    width: 100%;
    height: ${!hasData ? '200px' : '100%'};
    justify-content: center;
    align-items: center;
    background-color: ${colors.neutral00};
    animation: ${fadeInOpacity} 0.15s ease-in-out;
  `
);

const CenterFlexCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type FetchParameters<T> = {
  pageSize: number;
  pageIndex: number;
  sortBy: SortingRule<T>;
  filter?: string;
  searchTerm?: string;
};

type Data<T> = T[] | null;

export interface BaseTableProps<T extends ObjectOfAny> {
  columns?: Column<T>[];
  data?: Data<T>;
  totalRows?: number;
  pageCount?: number;
  loading?: boolean;
  sortable?: boolean;
  paginated?: boolean;
  rowsPerPage?: number[];
  onRowClick?: (row: T) => void;
  tableOptions?: Modify<TableOptions<T>, { data?: Data<T> }>;
  fetchData?: ({
    pageSize,
    pageIndex,
    sortBy,
    filter,
    searchTerm,
  }: FetchParameters<T>) => void;
  noDataText?: string;
  NoDataEmptyState?: React.FC;
  filter?: string;
  style?: SxStyleProp;
  initialState?: Partial<TableState<T>>;
  searchTerm?: string;
  tableAriaLabel?: string;
}

const Loader = ({ hasData }: { hasData: boolean }) => (
  <LoadingFlex hasData={hasData} data-testid="loading-animation">
    <Spinner size={72} relative />
  </LoadingFlex>
);

const NoDataRow = ({
  noDataText,
  NoDataEmptyState,
}: {
  noDataText?: string;
  NoDataEmptyState?: React.FC;
}) => (
  <table>
    <tbody>
      <tr>
        <CenterFlexCell height="200px">
          <Text fontSize="subTitle" data-testid="table-no-data-text">
            {noDataText || (NoDataEmptyState && <NoDataEmptyState />)}
          </Text>
        </CenterFlexCell>
      </tr>
    </tbody>
  </table>
);

const BaseTable = <T extends ObjectOfAny>(props: BaseTableProps<T>) => {
  const {
    columns: propColumns,
    data: propData,
    sortable: propSortable,
    paginated: propPaginated,
    loading,
    pageCount: propPageCount,
    totalRows: propTotalRows,
    rowsPerPage: propRowsPerPage,
    onRowClick,
    tableOptions: options,
    fetchData,
    noDataText,
    NoDataEmptyState,
    filter: propFilter,
    style,
    initialState,
    searchTerm,
    tableAriaLabel,
  } = props;

  const columns = propColumns ?? options?.columns ?? [];
  const isSortable = !!(
    propSortable ||
    options?.manualSortBy ||
    options?.autoResetSortBy
  );
  const isPaginated = !!(
    propPaginated ||
    options?.manualPagination ||
    options?.autoResetPage ||
    options?.pageCount
  );
  const _data = propData ?? options?.data;
  const rowsPerPage = propRowsPerPage || ROWS_PER_PAGE;
  const initialRowsPerPage = isPaginated
    ? initialState?.pageSize || rowsPerPage[0]
    : _data?.length;

  const [data, setData] = useState<Nullable<T[]>>(_data);
  const [hasInitialData, setHasInitialData] = useState(!isEmpty(_data));
  const [tableForceUpdateCounter, setTableForceUpdateCounter] = useState(0);

  const hasData = !isEmpty(data);

  const tableProps = useTable<T>(
    {
      ...options,
      columns,
      data: data || [],
      initialState: {
        pageIndex: 0,
        ...initialState,
        pageSize: initialRowsPerPage,
      },
      autoResetSortBy: options?.autoResetSortBy ?? hasData,
      autoResetPage: options?.autoResetPage ?? hasData,
      autoResetSelectedRows: false,
      autoResetGroupBy: false,
      disableResizing: true,
      disableGroupBy: true,
      disableMultiSort: true,
    },
    useFlexLayout,
    useSortBy,
    usePagination
  );

  const {
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = tableProps;

  const totalRows = propTotalRows ?? data?.length ?? 0;
  const pageCount =
    propPageCount ??
    options?.pageCount ??
    (data ? Math.ceil(data.length / pageSize) : 1);

  useEffect(() => {
    setHasInitialData(false);
  }, []);

  useEffect(() => {
    setData(_data);
  }, [_data]);

  useEffect(() => {
    if (hasInitialData || fetchData == null) return;
    fetchData?.({
      pageIndex,
      pageSize,
      sortBy: sortBy?.[0],
      filter: propFilter,
      searchTerm,
    });
  }, [sortBy, pageIndex, pageSize, tableForceUpdateCounter, searchTerm]);

  useEffect(() => {
    if (isEmpty(data)) return; // avoid first load trigger
    if (pageIndex === 0) {
      /* Only using 'gotoPage' causes the table to not update when the filter changes and it's on the first page.
      If the filter query is on the update useEffect dependencies it will trigger 2 updates, one for the filter and
      another for the page change.*/
      forceTableUpdate();
    } else {
      gotoPage(0);
    }
  }, [propFilter, searchTerm]);

  const handlePageChange = (newIndex: number) => {
    if (loading || newIndex < 0 || newIndex >= pageCount) return;
    gotoPage(newIndex);
  };

  const handlePageSizeChange = (pageSize: number) => {
    if (isEmpty(data)) return;
    setPageSize(pageSize);
  };

  const forceTableUpdate = () => {
    setTableForceUpdateCounter((counter) => counter + 1);
  };

  const showPaginationControls = isPaginated && hasData && pageCount >= 2;

  return !loading && data?.length === 0 ? (
    <NoDataRow noDataText={noDataText} NoDataEmptyState={NoDataEmptyState} />
  ) : (
    <Flex flexDirection="column" width="100%" minHeight="50vh" sx={style}>
      <Flex sx={{ position: 'relative', flex: '1' }}>
        <TableContent<T>
          sortable={isSortable}
          paginated={isPaginated}
          noDataText={noDataText}
          onRowClick={onRowClick}
          loading={loading}
          {...tableProps}
          data={data}
          tableAriaLabel={tableAriaLabel}
        />
        {loading && <Loader hasData={hasData} />}
      </Flex>
      {showPaginationControls && (
        <PaginationControls
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalRows={totalRows}
          rowPerPage={rowsPerPage}
          handlePageSizeChange={handlePageSizeChange}
          goToPage={handlePageChange}
          {...tableProps}
          pageCount={pageCount}
        />
      )}
    </Flex>
  );
};

export default BaseTable;
